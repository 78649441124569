<template>
  <div class="action-group flex flex-col justify-center border-b border-gray-600 select-none w-full min-h-input">
    <CollapseHeader
      :label="label"
      :icon="icon"
      :icon-class="iconClass"
      :collapsed="collapsed"
      :collapsable="hasBody"
      :bg-class="bgClass"
      :help-text="helpText"
      @toggle-block-collapse="collapsed = !collapsed"
    >
      <slot name="header" />
    </CollapseHeader>

    <div
      v-if="!collapsed"
      class="flex justify-end w-full bg-gray-700"
      :class="slotClass"
    >
      <slot />
    </div>
  </div>
</template>
<script>
const CollapseHeader = () => import('./CollapseHeader')
export default {
  name: 'CollapseGroup',

  components: {
    CollapseHeader
  },

  props: {
    label: {
      type: String,
      required: true
    },

    icon: {
      type: String,
      default: ''
    },

    iconClass: {
      type: String,
      default: ''
    },

    helpText: {
      type: String,
      default: ''
    },

    level: {
      type: Number,
      default: 1
    },

    closed: {
      type: Boolean,
      default: true
    },

    overrideClass: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      collapsed: true
    }
  },

  computed: {

    hasBody () {
      return !!this.$slots['default']
    },

    bgClass () {
      if (this.overrideClass !== '') return this.overrideClass
      if (this.level > 1) return 'pl-0 bg-gray-700 text-gray-200'
      return 'text-gray-200'
    },

    slotClass () {
      if (this.level > 1) return ''
      return 'pr-2'
    },

    isCollapsable () {
      return this.collapsable === true
    }
  },

  mounted () {
    this.collapsed = this.closed
  }

}
</script>
